.page-header {
    padding-top: 70px;
}

.hero {
    border-bottom: 1px solid #c7c7c7;

    .hero-inner {
        padding-top: 140px;
        padding-bottom: 140px;
        text-align: center;

        @include laptop {
            padding-top: 160px;
            padding-bottom: 160px;
        }
        @include laptop {
            padding-top: 190px;
            padding-bottom: 190px;
        }
        h2 {
            font-size: 60px;
            color: #000;
            font-weight: 100;
        }
        p {
            font-size: 24px;
            font-weight: 100;
        }
    }
}

.strapline {
    text-align: center;
    font-size: 29px;
    color: #463716;
    padding-top: 20px;
    padding-bottom: 20px;

    p {
        margin-bottom: 0;
        font-weight: 300;
        color: #683926;
        font-size: 30px;
    }

    .strap-images {
        margin-top: 40px;
        margin-bottom: 40px;

        .strap-image {
            margin-bottom: 25px;

            &:last-of-type {
                margin-bottom: 0;
            }
            @include tablet {
                margin-bottom: 0;
            }

            img {
                max-width: 100%;
                display: block;
                margin: 0 auto;
                max-height: 65px;
            }
        }
    }
}

.workers-compensation {
    background-image: url(/wp-content/uploads/workers-compensation-bg.jpg);
}
.labor-employment-law {
    background-image: url(/wp-content/uploads/labor-employment-law-bg.jpg);
}
.workers-compensation,
.labor-employment-law {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    text-align: center;

    @include mobile {
        text-align: left;
    }
}
.insurance-defense {
    text-align: center;
    background-color: #323232;
}
.workers-compensation,
.labor-employment-law,
.insurance-defense {
    padding-top: 80px;
    padding-bottom: 80px;
    color: #fff;

    h2 {
        color: #fff;
    }
}


.clients {
    .client {
        position: relative;
        padding-left: 25px;
        margin-bottom: 3px;
        font-size: 14px;

        &:before {
            position: absolute;
            left: 10px;
            font-family: FontAwesome;
            content: "\f0da";
        }

        @include tablet {
            padding-left: 0;
            margin-bottom: 20px;
            height: 50px;
            font-size: 20px;

            &:before {
                content: "";
            }
        }
    }
}

.contact-inner {
    padding-top: 40px;
}
