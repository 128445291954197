aside {
    margin-bottom: 20px;

    .widget_categories {
        ul {
            li {
                position: relative;

                &.has-children > a {
                    margin-right: 30px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        & > ul {
            list-style-type: none;
            margin: 0 0 20px 0;
            padding: 0;

            & > li {
                border-bottom: 1px solid #ccc;

                a {
                    color: #333;
                    display: block;
                    padding: 10px;
                    text-decoration: none;

                    &:hover, &:focus {
                        color: #683926;
                        background-color: #f0f0f0;
                        text-decoration: none;
                    }
                }

                ul.children {
                    display: none;
                    padding-left: 20px;
                    list-style: none;

                    li {

                    }
                }
            }
        }

        .expand {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 30px;
            height: auto;
            text-align: center;

            i {
                margin-top: 5px;
                width: 100%;
                font-size: 24px;
                transition: transform 0.2s ease-in-out;
            }

            &.expanded {
                i {
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }
    }

    .widget_tag_cloud {
        a {
            border: 1px solid #ccc;
            background: #fff;
            padding: 10px;
            border-radius: 4px;
            float: left;
            margin-right: 4px;
            margin-bottom: 4px;
            font-size: 14px!important;
        }
    }
}
