// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper {
  ul {
    padding: 0;
    list-style-type: none;

    li {
      @extend p;
    }
  }

  input[type="submit"] {
    appearance: none;
    cursor: pointer;

    @extend .btn;
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}

.wpcf7,
.gform_wrapper {
    label {
        display: block;
        margin-bottom: 5px;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        width: 100%;
        margin-top: 5px;
    }

    textarea {
        min-height: 150px;
        max-width: 100%;
    }

    .form-group, .wpcf7-recaptcha {
        margin-bottom: 10px;
    }
}
