html * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
    font-family: $body-font;
    background: #fff;
    margin: 0;
    padding: 0;
    font-size: $font-size;
    line-height: $line-height;

    main.main {
        padding-top: 0;
    }

    &.tag {
        display: block;
        padding: 0;
        border-radius: 0;
        font-size: $font-size;
        font-weight: normal;
        line-height: $line-height;
        text-align: left;
        color: #000;
        white-space: normal;
    }
}

h1,h2,h3,h4,h5,h6 {
    margin: 0 0 $heading-margin-bottom 0;
    font-weight: $heading-font-weight;
    font-family: $heading-font
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 300;
  color: $heading-color;
}
h1, .h1 {
  font-size: $h1-size;
}
h2, .h2 {
  font-size: $h2-size;
}
h3, .h3 {
  font-size: $h3-size;
}
h4, .h4 {
  font-size: $h4-size;
}
h5, .h5 {
  font-size: $h5-size;
}
h6, .h6 {
  font-size: $h6-size;
}

a {
  color: $link-color;
}

input[type="text"], input[type="email"], input[type="tel"],
input[type="password"], textarea {
  width: 100%;
  border: 1px solid #ccc;
  padding: 4px 0 4px 10px;

  &:focus {
    box-shadow: 0 0 3px $brand-primary;
    outline:none;
  }
}

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.btn {
    display:inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 100;
    background: $btn-color;
    color: $btn-text-color;
    text-decoration:none;

    &:hover {
        background: darken($btn-color, 10%);
        color: $btn-text-color;
        text-decoration: none;
    }
}

.btn-secondary {
    background: $btn-secondary-color;
    display:inline-block;
    padding: 10px 20px;
    color: $btn-secondary-text-color;
    text-decoration:none;

    &:hover {
        background: darken($btn-secondary-color, 10%);
        text-decoration: none;
    }
}

.read-more {
    margin-top: 10px;
}

.google-map {
  margin-top: 54px;
  overflow: hidden;
  height: 500px;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
    position: relative;
    top: -54px;
  }
}

.modal-header h2 {
  margin-bottom: 0;
  display: inline-block;
}

.modal .btn.btn-big {
  font-size: 18px;
  padding: 15px 20px;
  min-width: 100px;
}
.modal:before {
  content: "";
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.modal:target:before {
  display: block;
}
.modal .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  top: 20%;
}
.modal-dialog {
  background: #fefefe;
  border: #333333 solid 1px;
  border-radius: 5px;
  //margin-left: -200px;
  position: fixed;
  left: -100%;
  right: -100%;
  z-index: 11;
  width: 360px;
  -webkit-transform: translate(0, -500%);
  -ms-transform: translate(0, -500%);
  transform: translate(0, -500%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal-body {
  padding: 20px;
}
.modal-header,
.modal-footer {
  padding: 10px 20px;
}
.modal-header {
  border-bottom: #eeeeee solid 1px;
}
.modal-header h2 {
  font-size: 20px;
}
.modal-footer {
  border-top: #eeeeee solid 1px;
  text-align: right;
}
