.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    top: 0px;
    background: #fff;
    transition: all 0.3s ease;
    border-left: 1px solid #c7c7c7;

    h3 {
        color: #fff;
        font-size: 24px;
        padding: 20px;
        margin: 0;
        font-weight: 300;
        background: #111;
    }

    a {
        display: block;
        color: #000;
        font-size: 16px;
        border-bottom: 1px solid #c7c7c7;
        padding: 20px;

        &:hover, &:focus {
            color: $brand-primary;
            text-decoration: none;
        }
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }

    .dropdown-backdrop {
        position: relative;
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
}
