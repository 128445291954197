footer.footer {
    border-top: 1px solid #c7c7c7;
    padding-top: 40px;

    .menu-primary-container {
        margin-bottom: 28px;

        ul.nav {
            li {
                display: block;
                text-align: center;

                a {
                    padding: 10px 15px;
                    font-size: 24px;
                    text-decoration: none;
                    color: black;
                    font-weight: 100;

                    @include laptop {
                        font-size: 21px
                    }
                    @include desktop {
                        font-size: 18px;
                    }

                    &:hover, &:focus {
                        color: $brand-primary;
                    }
                }

                &.phone {
                    display: none;
                }

                @include laptop {
                    display: inline-block;
                }
            }
        }
    }

    .addresses {
        margin-bottom: 5px;
    }

    .social {

    }

    .copyright {
        padding: 10px 0;
        margin-top: 20px;
        background-color: #333;
        color: #fff;
        font-size: 14px;
        text-align: center;

        a {
            color: #fff;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }
}
