header.header {
    border-bottom: 1px solid #c7c7c7;

    .container {
        position: relative;
    }

    nav.nav-primary {
        margin-top: 36px;

        ul {
            li {
                display: inline-block;

                a {
                    padding: 10px 10px;
                    color: #000;
                    font-weight: 100;
                    font-size: 16px;
                    text-decoration: none;

                    &:hover, &:focus {
                        color: $brand-primary;
                    }
                }
            }
        }
    }
}
