// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary: #683926;      //Primary Brand Color
$brand-secondary: #fff;       //Secondary Brand Color
$dark-gray: #999;             //Third Color
$gray: #bbb;                  //Fourth Color
$light-gray: #eee;            //Fifth Color
$header-text-color: #fff;     //Color for text in the site header

$body-font: "proxima-nova", Helvetica, sans-serif;     // Set Site Base Font Applied to Body Tag
$heading-font: "proxima-nova", Helvetica, sans-serif;  // Set Base Heading Font
$heading-font-weight: bold;                   // Set Default Heading Weight
$font-size: 16px;                             // Set Default Font Size Applied to Body Tag
$heading-margin-bottom: 20px;                 // Set Default Bottom Margin for all headings
$paragraph-margin-bottom: 20px;               // Set Default Bottom Margin for Paragraphs
$line-height: 1.2;                            // Set Default Line Height Applied to Body Tag
$heading-color: $brand-primary;               // Set Heading Color
$link-color: $brand-primary;
$h1-size: 40px;
$h2-size: 35px;
$h3-size: 30px;
$h4-size: 25px;
$h5-size: 20px;
$h6-size: 18px;

$btn-color: $brand-primary;   // Button Background Color
$btn-text-color: $brand-secondary; // Button text color
$btn-secondary-color: $brand-secondary;
$btn-secondary-text-color: #333;
$btn-group-border-color: #333;

$sideNavWidth: 240px;
$sideNavDirection: (right);

$button_width: 40px;     // width of button area
$button_height: 80px;     // height of button area
$bar_thickness: 6px;     // thickness of button bars
$bar_roundness: 6px;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $brand-primary;
$hamburger_active: $brand-primary;

// Breakpoint variables
$width_mobile: 480px;
$width_phablet: 560px;
$width_tablet: 768px;
$width_laptop: 992px;
$width_desktop: 1200px;



/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
