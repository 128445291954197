.blog, .archive, .single-post, .tag {
    main.main {
        margin-top: 60px;
    }
    article {
        background: #fff;
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 20px;
        color: #000;
        @include clearfix;

        h2{
            margin-bottom:0;

            a {
                color: $brand-primary;
        	    font-size: 30px;
        	    margin-bottom: 0;
                text-decoration: none;
            }
        }

        time.updated {
            color: #ccc;
            font-size: 14px;
            font-style: italic;
            display: block;
            margin-bottom: 20px;
            border-bottom: 1px solid #ccc;
        }

        img {
            margin-bottom: 15px;
            width: 100%;
        }
    }
}

nav.pagination {
    display: block;
    //padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0;

    .nav-links {
        text-align: center;

        &:after {
            content:"";
            display:block;
            clear:both;
        }

        .page-numbers {
            display: inline-block;
            padding: 9px 3px 6px;
            font-size: 20px;

            &.prev, &.next {
                padding: 9px 15px 6px;
            }
            &.prev {
                float: left;
                border-right: 1px solid #ddd;
            }
            &.next {
                float: right;
                border-left: 1px solid #ddd;
            }

            &.current {
                cursor: not-allowed;
                color: #ccc;
            }
        }
    }
}

.attorney {
    margin-bottom: 40px;

    .attorney-name {
        h4 {
            margin-bottom: 0;
        }
    }

    .attorney-social {
        margin-bottom: 10px;
        text-align: left;

        @include phablet {
            text-align: right;
        }

        .social {
            i {
                font-size: 34px;
            }
            .fa-linkedin-square {
                font-size: 32px;
            }
        }
    }

    .attorney-description {
        img.attorney-image {
            max-width: 375px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-bottom: 10px;
            border: 2px solid #7C6A54;

            @include tablet
            @include laptop {
                float: right;
                margin-bottom: 5px;
                margin-left: 10px;
                max-width: 200px;
            }
        }
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

/* Staff entries */
.staffs {
    margin-bottom: 40px;

    .staff {
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .staff-name {
            font-weight: bold;
        }
        .staff-position {

        }
        .staff-time {
            display: block;
        }
    }
}
